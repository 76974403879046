import React, { useState, useEffect, useMemo, useRef } from "react";
import { Avatar, message } from "antd";
import TabsWithBadge from "../../common/tabsWithBadge";
import { Button } from "../../common/button";

import * as voiceService from "../../services/voiceService";

import authService from "../../services/authService";

import {
  useLocation,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";

import { useSelectedConversation } from "./models/useSelectedConversation";
import { useRetryExtration } from "./models/useRetryExtraction";

import { SearchConversation } from "./components/search";
import { Conversations } from "./components/conversations";
import {
  ConversationMessage,
  ConversationMessages,
  ConversationMessagesView,
} from "./components/conversation_messages";
import { ConversationDetails } from "./components/conversation_details";
import { ConversationMoreOptions } from "./components/conversation_more_options";
import { ConversationDeletion } from "./components/conversation_deletion";
import { ConversationSkeleton } from "./components/conversations_skeleton";
import { ThreadRecording } from "./components/Thread_recording";
import { ConversationItem } from "./components/conversation_item";
import { UserIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useWhatsappSend } from "./models/useWhatsappSend";
import { usePaginatedConversations } from "./models/usePaginatedConversations";

const TABS = {
  ASSIGNED_TO_ME: "Assigned",
  ALL: "All",
};

export function ChatEditor(props) {
  const { conversation, updateLocalMessage, onSuccess } = props;

  const { sendWhatsappMessage } = useWhatsappSend();

  const ref = useRef();

  const onSend = () => {
    const data = {
      toNumber: conversation.userIdentifier,
      message: ref.current.textContent,
    };

    updateLocalMessage(ref.current.textContent);
    ref.current.textContent = "";

    sendWhatsappMessage(conversation.project, data, onSuccess);
  };

  useEffect(() => {
    const enterKeyEventListener = (event) => {
      event.stopPropagation();
      if (event.keyCode === 13 && !event.shiftKey) {
        event.preventDefault();
        onSend();
      }
    };

    ref.current.addEventListener("keydown", enterKeyEventListener);
  }, []);

  return (
    <div className="flex flex-none border-t shadow items-center justify-end p-4 gap-x-4">
      <div className="w-full border bg-white p-2 rounded-lg min-w-0">
        <div
          ref={ref}
          contentEditable="true"
          className="border-none focus:outline-none whitespace-pre break-words min-h-[1rem] max-h-[8rem] text-wrap overflow-scroll"
        />
      </div>
      <div>
        <Button onClick={onSend}>Send</Button>
      </div>
    </div>
  );
}

function useHumanAssignmentFilteredConversations(conversations) {
  const user = authService.getCurrentUser();

  return conversations.reduce(
    (acc, conversation) => {
      if (
        conversation.sourceOfConversation === "WHATSAPP" &&
        conversation.humanAgentAssignmentSettings &&
        conversation.humanAgentAssignmentSettings?.humanAgentAssigned &&
        conversation.status === 0
      ) {
        const { humanAgentAssignmentSettings } = conversation;
        const { humanAgentAssigned } = humanAgentAssignmentSettings;

        if (humanAgentAssigned?.email === user.email) {
          const updatedAcc = {
            ...acc,
            assingedToMeConversations: [
              ...acc.assingedToMeConversations,
              conversation,
            ],
          };

          return updatedAcc;
        }
      }

      return acc;
    },
    { assingedToMeConversations: [], allConversations: conversations }
  );
}

export function Threads() {
  const {
    projects,
    activeProject,
    settings,
    searchParams,
    setSearchParams,
    projectId,
  } = useOutletContext();
  const location = useLocation();

  // const { conversations, fetchConversations } = useConversations();
  const {
    totalConversations,
    conversations,
    totalPages,
    searchTerm,
    loading,
    showLoadMoreConversation,
    fetchPaginatedConversations,
    fetchConversationsBySearchTerm,
    loadMoreConversations,
    updateSearchTerm,
    resetConversations,
  } = usePaginatedConversations(projects[activeProject]?._id);

  const { assingedToMeConversations, allConversations } =
    useHumanAssignmentFilteredConversations(conversations);

  const { selectedConversation, updateSelectedConversation } =
    useSelectedConversation();

  const { handleRetry } = useRetryExtration();

  const [callRecordingUrl, setCallRecordingURL] = useState("");
  const [activeTab, setActiveTab] = useState(
    searchParams.get("tab") || TABS.ALL
  );

  const [isOpen, setIsOpen] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [isLoadingRecording, setIsLoadingRecording] = useState(false);
  const searchRef = useRef(null);

  const showThreadRecording = useMemo(() => {
    const limitRecordingVisibility =
      settings?.voiceChatSettings?.limitRecordingVisibility;
    const currentUser = authService.getCurrentUser();

    return (
      !limitRecordingVisibility ||
      (limitRecordingVisibility && currentUser.isCompanyOwner)
    );
  }, [settings]);

  const tabs = useMemo(() => {
    return Object.values(TABS).map((tab) => {
      let count = totalConversations;
      if (tab === TABS.ASSIGNED_TO_ME) {
        count = assingedToMeConversations.length;
      }
      return {
        name: tab,
        current: tab === activeTab,
        count,
      };
    });
  }, [activeTab, totalConversations, assingedToMeConversations]);

  useEffect(() => {
    if (Object.keys(selectedConversation).length > 0) {
      setShowDetails(true);
    } else {
      setShowDetails(false);
    }
  }, [selectedConversation]);

  useEffect(() => {
    resetConversations();
    updateSelectedConversation({});
    updateSearchTerm("");

    if (searchRef?.current) {
      searchRef.current.reset();
    }

    if (projectId) {
      fetchPaginatedConversations(1, 50);
    }

    return () => {
      resetConversations();
      updateSelectedConversation({});
      updateSearchTerm("");
    };
  }, [projectId]);

  useEffect(() => {
    updateSelectedConversation({});

    if (projectId && searchTerm === "") {
      fetchPaginatedConversations(1, 50);
    }

    if (projectId && searchTerm != "") {
      fetchConversationsBySearchTerm();
    }
  }, [searchTerm]);

  useEffect(() => {
    setCallRecordingURL("");
  }, [selectedConversation]);

  useEffect(() => {
    return () => {
      if (callRecordingUrl) {
        URL.revokeObjectURL(callRecordingUrl);
      }
    };
  }, [callRecordingUrl]);

  useEffect(() => {
    setSearchParams((prevParams) => {
      prevParams.set("tab", activeTab);
      return prevParams;
    });
  }, [activeTab, projectId, location.search]);

  const handleGetRecordings = async () => {
    try {
      setIsLoadingRecording(true);
      const response = await voiceService.getRecordingLink(
        selectedConversation.project,
        selectedConversation._id
      );
      const blob = response.data;
      const audioUrl = URL.createObjectURL(blob);
      setCallRecordingURL(audioUrl);
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        message.error(ex.response.data);
        return;
      }
    } finally {
      setIsLoadingRecording(false);
    }
  };

  useEffect(() => {
    const listener = (event) => {
      const { title, data } = event.data;

      // if (title === "Human Agent Requested by Customer") {
      fetchPaginatedConversations(1, 100, false);
      // }
    };

    navigator.serviceWorker.addEventListener("message", listener);
    return () =>
      navigator.serviceWorker.removeEventListener("message", listener);
  }, [fetchPaginatedConversations]);

  function handleSearch(event) {
    updateSearchTerm(event.target.value);
  }

  return (
    <div className="flex h-[calc(100vh-16px)] rounded-lg">
      <div className="flex-none w-1/4 border-r border-gray-200 flex flex-col border-b ">
        <div className="flex flex-col gap-y-4 justify-between px-4 pt-4 border-b border-gray-200 sticky top-0">
          <h2 className="text-lg font-medium text-gray-800">Threads</h2>
          <SearchConversation ref={searchRef} handleSearch={handleSearch} />
          <TabsWithBadge
            tabs={tabs}
            onTabChange={(e, { tab, index }) => {
              setActiveTab(tab.name);
              updateSelectedConversation({});
              setShowDetails(false);
            }}
          />
        </div>
        <div className="overflow-y-auto overflow-x-hidden flex-1">
          {activeTab === TABS.ALL && (
            <Conversations
              conversations={allConversations}
              isSelectedConversation={(itemId) =>
                itemId === selectedConversation._id
              }
              updateSelectedConversation={updateSelectedConversation}
              showLoadMoreConversation={showLoadMoreConversation}
              loadMoreConversations={loadMoreConversations}
              loading={loading}
            />
          )}
          {activeTab === TABS.ASSIGNED_TO_ME && (
            <Conversations
              conversations={assingedToMeConversations}
              isSelectedConversation={(itemId) =>
                itemId === selectedConversation._id
              }
              updateSelectedConversation={updateSelectedConversation}
              showLoadMoreConversation={false}
              loadMoreConversations={loadMoreConversations}
              loading={loading}
            />
          )}
        </div>
      </div>

      {selectedConversation?.sourceOfConversation &&
        ConversationMessage[selectedConversation.sourceOfConversation]({
          key: selectedConversation._id,
          selectedConversation,
          onDeleteConversation: () => setIsOpen(true),
          onViewContactClick: () => setShowDetails(true),
          onRetryExtraction: () => {
            handleRetry(
              selectedConversation?.project,
              selectedConversation?._id
            );
          },
          fetchConversations: fetchPaginatedConversations,
          resetSelectedConversation: () => updateSelectedConversation({}),
          loading,
        })}

      {showDetails && (
        <aside className="w-1/3 bg-white">
          <div className="flex flex-col m-4">
            <div className="flex gap-x-4 items-center min-h-16">
              <Button plain onClick={() => setShowDetails(false)}>
                <XMarkIcon className="size-6" />
              </Button>
              <p className="font-semibold text-gray-700">Details</p>
            </div>
            {showThreadRecording && (
              <div className="p-4">
                <ThreadRecording
                  isLoadingRecording={isLoadingRecording}
                  callRecordingUrl={callRecordingUrl}
                  selectedItem={selectedConversation}
                  handleGetRecordings={handleGetRecordings}
                />
              </div>
            )}

            <ConversationDetails
              conversation={selectedConversation}
              settings={settings}
            />
          </div>
        </aside>
      )}
      <ConversationDeletion
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        conversationId={selectedConversation?._id}
        projectId={projects[activeProject]?._id}
        fetchConversations={() => {}}
      />
    </div>
  );
}
