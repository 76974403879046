import Joi from "joi-browser";

export const sheetURLFieldTemplate = {
  name: "sheetURL",
  label: "Google Sheet Link",
  description: `Please make sure the Google sheet is public`,
  placeholder: "Paste here",
  type: "text",
};

export const sheetURLFieldSchema = {
  sheetURL: Joi.string().uri().required().label("sheetURL"),
};
