import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "antd";

const formatDate = (timestamp, timeZone) => {
  const date = new Date(timestamp);
  return new Intl.DateTimeFormat("default", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone,
    timeZoneName: "short",
  }).format(date);
};

export function ConversationDetails(props) {
  const { conversation, settings } = props;

  const timeZone = settings?.campaignSettings?.campaignTimeZone;

  return (
    <div className="bg-white border rounded-lg p-4 mt-6">
      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-start">
          <span className="bg-white pr-2 text-sm text-gray-500">
            Conversation Details
          </span>
        </div>
      </div>
      {conversation?.callId && (
        <div className="mt-4 flex justify-between text-sm">
          <span className="text-gray-500">Call ID</span>
          <span className="text-xs text-gray-400">{conversation?.callId}</span>
        </div>
      )}
      {conversation?.attributes?.callDuration && (
        <div className="mt-4 flex justify-between text-sm">
          <span className="text-gray-500">Call Duration</span>
          <span className="text-gray-400">
            {conversation?.attributes?.callDuration}
          </span>
        </div>
      )}
      {conversation?.attributes?.phone && (
        <div className="mt-4 flex justify-between text-sm">
          <span className="text-gray-500">Call From</span>
          <span className="text-gray-400">
            {conversation &&
              conversation.attributes &&
              conversation.attributes.phone}
          </span>
        </div>
      )}
      {/* <div className="mt-4 flex justify-between">
        <span className="text-gray-500">Call type</span>
        <span className="text-gray-400">
          {conversation &&
          conversation.attributes &&
          conversation.attributes.type === "twilioCall"
            ? "Programmatic Call"
            : "WebCall"}
        </span>
      </div> */}
      <div className="mt-4 flex justify-between text-sm gap-x-4">
        <span className="text-gray-500">
          Start time
          <Tooltip title="Time shown as per workspace timezone">
            <ExclamationCircleIcon className="size-4 inline mx-1 cursor-pointer" />
          </Tooltip>
        </span>
        <span className="text-gray-400">
          {conversation && conversation.createdAt
            ? formatDate(conversation.createdAt, timeZone)
            : ""}
        </span>
      </div>
    </div>
  );
}
