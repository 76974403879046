import { message } from "antd";
import { useState, useEffect } from "react";
import TabsWithBadge from "v2/common/tabsWithBadge";
import { updateProject } from "v2/services/projectService";
import Joi from "joi-browser";
import Form from "v2/components/Form";
import { Field, Label } from "v2/common/fieldset";

const TABS = {
  INBOUND: "Inbound Settings",
  OUTBOUND: "Outbound Settings",
};

const Tabs = {
  [TABS.INBOUND]: (props) => <Inbound {...props} />,
  [TABS.OUTBOUND]: (props) => <Outbound {...props} />,
};

function WelcomeMessageForm(props) {
  const {
    loading,
    setLoading,
    template,
    schema,
    welcomeMessageSettings,
    onSubmit,
  } = props;
  const [errorMessage] = useState({});

  const buttonInfo = { label: "Save", style: "right-side" };

  return (
    <div className="flex flex-col">
      <div></div>
      <Form
        key={JSON.stringify(welcomeMessageSettings)}
        errorMessage={errorMessage}
        inputField={welcomeMessageSettings}
        template={template}
        schema={schema}
        onSubmit={onSubmit}
        loading={loading}
        setLoading={setLoading}
        buttonInfo={buttonInfo}
        enableActionsOnChange={true}
        showRevert={true}
      />
    </div>
  );
}

function Inbound(props) {
  const {
    loading,
    setLoading,
    schema,
    welcomeMessageSettings,
    onWelcomeMessageSubmit,
  } = props;

  const template = [
    {
      name: "shouldAgentSpeakFirstInbound",
      label: "Initiate Conversation",
      description: "Enable to let the Agent initiate the conversation",
      type: "toggle",
    },
    {
      name: "inboundWelcomeMessages",
      label: "Inbound Welcome Message",
      type: "textArea",
      style: { height: "40px" },
    },
  ];

  return (
    <WelcomeMessageForm
      onSubmit={onWelcomeMessageSubmit}
      welcomeMessageSettings={welcomeMessageSettings}
      schema={schema}
      template={template}
      loading={loading}
      setLoading={setLoading}
    />
  );
}

function Outbound(props) {
  const {
    loading,
    setLoading,
    schema,
    welcomeMessageSettings,
    onWelcomeMessageSubmit,
  } = props;

  const template = [
    {
      name: "shouldAgentSpeakFirstOutbound",
      label: "Initiate Conversation",
      description: "Enable to let the Agent initiate the conversation",
      type: "toggle",
    },
    {
      name: "outboundWelcomeMessages",
      label: "Outbound Welcome Message",
      type: "textArea",
      style: { height: "40px" },
    },
  ];

  return (
    <WelcomeMessageForm
      onSubmit={onWelcomeMessageSubmit}
      welcomeMessageSettings={welcomeMessageSettings}
      schema={schema}
      template={template}
      loading={loading}
      setLoading={setLoading}
    />
  );
}

export function WelcomeMessage(props) {
  const { projectId, settings, fetchSettings } = props;

  const [welcomeMessageSettings, setWelcomeMessageSettings] = useState({
    inboundWelcomeMessages: "",
    outboundWelcomeMessages: "",
    shouldAgentSpeakFirstInbound: false,
    shouldAgentSpeakFirstOutbound: false,
  });
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(TABS.INBOUND);

  const tabs = Object.values(TABS).reduce((acc, tab) => {
    return [...acc, { name: tab, href: "#", current: tab === activeTab }];
  }, []);

  useEffect(() => {
    if (settings && settings?.voiceChatSettings) {
      const { _id, inboundWelcomeMessages, outboundWelcomeMessages, ...rest } =
        settings.voiceChatSettings.welcomeMessageSettings;

      setWelcomeMessageSettings((prevSettings) => ({
        ...prevSettings,
        ...rest,
        inboundWelcomeMessages: inboundWelcomeMessages
          ? inboundWelcomeMessages[0]
          : "",
        outboundWelcomeMessages: outboundWelcomeMessages
          ? outboundWelcomeMessages[0]
          : "",
      }));
    }
  }, [settings]);

  const updateWelcomeMessageSettings = async (data) => {
    try {
      setLoading(true);
      const response = await updateProject(projectId, {
        voiceChatSettings: { welcomeMessageSettings: data },
      });
      if (response.status === 200) {
        message.success("Changes saved");
        fetchSettings();
      } else {
        message.error("Failed to save changes");
      }
    } catch (error) {
      message.error("An error occurred: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const schema = {
    inboundWelcomeMessages: Joi.string()
      .max(350)
      .allow(null, "")
      .label("Welcome message"),
    outboundWelcomeMessages: Joi.string()
      .allow(null, "")
      .max(350)
      .label("Welcome message"),
    shouldAgentSpeakFirstInbound: Joi.boolean()
      .required()
      .label("Initiate Conversation"),
    shouldAgentSpeakFirstOutbound: Joi.boolean()
      .required()
      .label("Initiate Conversation"),
  };

  return (
    <div className="mt-4">
      <Field>
        <Label>Welcome Message</Label>
      </Field>
      <div className="mt-2 flex flex-col border rounded-lg">
        <div className="">
          <TabsWithBadge
            className="px-4 border-b"
            tabs={tabs}
            onTabChange={(e, { tab }) => setActiveTab(tab.name)}
          />
          <div className="px-8 pb-8">
            {Tabs[activeTab]({
              loading,
              setLoading,
              welcomeMessageSettings,
              setWelcomeMessageSettings,
              schema,
              onWelcomeMessageSubmit: updateWelcomeMessageSettings,
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
